import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M 24.6411 49.3727 L 34.5162 26.58 L 40.6011 26.58 L 40.6011 63.338 L 34.5162 63.338 L 34.5162 38.0515 L 26.287 58.5 L 24.6411 58.5 L 24.6411 49.3727 Z M 6.636 63.338 L 6.636 26.58 L 12.7209 26.58 L 22.596 49.2735 L 22.596 58.5 L 21.0004 58.5 L 12.7209 38.0515 L 12.7209 63.338 L 6.636 63.338 Z M 49.7284 63.338 L 49.7284 26.58 L 55.7133 26.58 L 55.7133 63.338 L 49.7284 63.338 Z M 57.7576 26.58 L 60.3017 26.58 Q 62.6459 26.58 64.9398 27.3278 A 12.5404 12.5404 90 0 1 69.0547 29.5728 A 11.7378 11.7378 90 0 1 71.9972 33.1887 Q 73.1193 35.3082 73.1193 37.9515 Q 73.1193 40.7445 72.1716 43.0135 A 10.9068 10.9068 90 0 1 70.3527 45.9837 A 10.0904 10.0904 90 0 1 69.5282 46.8293 A 11.4554 11.4554 90 0 1 66.4523 48.8189 A 13.7289 13.7289 90 0 1 65.4388 49.2231 A 14.7788 14.7788 90 0 1 61.9535 49.9841 A 18.2203 18.2203 90 0 1 60.1522 50.0709 L 57.7576 50.0709 L 57.7576 45.6824 L 61.1991 45.6824 A 6.546 6.546 90 0 0 63.369 45.3081 A 5.3735 5.3735 90 0 0 65.0543 44.3392 A 6.3337 6.3337 90 0 0 65.3389 44.086 Q 66.1444 43.3258 66.6682 42.0234 A 8.9412 8.9412 90 0 0 66.785 41.717 Q 67.23 40.4847 67.3139 38.7767 A 16.7429 16.7429 90 0 0 67.3336 37.9515 A 7.8841 7.8841 90 0 0 67.2862 37.1132 Q 67.2249 36.5412 67.0848 35.8817 A 5.7463 5.7463 90 0 0 66.3545 34.0774 A 6.8816 6.8816 90 0 0 66.0619 33.6374 A 5.4523 5.4523 90 0 0 64.861 32.4401 A 7.2362 7.2362 90 0 0 63.8928 31.8419 Q 62.8327 31.2932 61.256 31.1604 A 12.6199 12.6199 90 0 0 60.2018 31.1188 L 57.7576 31.1188 L 57.7576 26.58 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="square"
        strokeLinejoin="square"
        d="M 50, 15
        L 13, 15
        L 13, 88
        L 50, 88
        L 89, 88
        L 89, 15 z"
      />
    </g>
  </svg>
);

export default IconLoader;
