import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 150 150">
    <title>Logo</title>
    <g transform="translate(0.000000, 0.000000)">
      <g transform="translate(0.000000, 0.000000)">
        <path
          d="M 49.5878 87.9872 L 66.9126 48 L 77.5878 48 L 77.5878 112.4877 L 66.9126 112.4877 L 66.9126 68.1254 L 52.4755 104 L 49.5878 104 L 49.5878 87.9872 Z M 18 112.4877 L 18 48 L 28.6752 48 L 46 87.8131 L 46 104 L 43.2006 104 L 28.6752 68.1254 L 28.6752 112.4877 L 18 112.4877 Z M 93.6006 112.4877 L 93.6006 48 L 104.1005 48 L 104.1005 112.4877 L 93.6006 112.4877 Z M 107.687 48 L 112.1504 48 Q 116.263 48 120.2874 49.312 A 22.0006 22.0006 90 0 1 127.5066 53.2506 A 20.5926 20.5926 90 0 1 132.6688 59.5942 Q 134.6374 63.3126 134.6374 67.9501 Q 134.6374 72.8499 132.9747 76.8307 A 19.1347 19.1347 90 0 1 129.7837 82.0416 A 17.7024 17.7024 90 0 1 128.3373 83.5251 A 20.0973 20.0973 90 0 1 122.9408 87.0157 A 24.0858 24.0858 90 0 1 121.1629 87.7248 A 25.9277 25.9277 90 0 1 115.0483 89.0598 A 31.9654 31.9654 90 0 1 111.888 89.2122 L 107.687 89.2122 L 107.687 81.513 L 113.7248 81.513 A 11.4842 11.4842 90 0 0 117.5315 80.8563 A 9.4272 9.4272 90 0 0 120.4883 79.1565 A 11.1117 11.1117 90 0 0 120.9875 78.7123 Q 122.4006 77.3786 123.3197 75.0938 A 15.6864 15.6864 90 0 0 123.5245 74.5562 Q 124.3053 72.3942 124.4525 69.3978 A 29.3734 29.3734 90 0 0 124.487 67.9501 A 13.8317 13.8317 90 0 0 124.4038 66.4794 Q 124.2963 65.4758 124.0506 64.3187 A 10.0813 10.0813 90 0 0 122.7693 61.1533 A 12.073 12.073 90 0 0 122.256 60.3814 A 9.5654 9.5654 90 0 0 120.1491 58.281 A 12.695 12.695 90 0 0 118.4506 57.2314 Q 116.5907 56.2688 113.8246 56.0358 A 22.1402 22.1402 90 0 0 111.975 55.9629 L 107.687 55.9629 L 107.687 48 Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="7"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="55 10 5 10 5 150 55 150 145 150 145 10"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
